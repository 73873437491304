import React, { useState } from 'react';
import { logout } from 'modules/auth/utils';
import { useUser } from 'modules/context/Contexts/User';

import useToggle from '@lyearn/core/hooks/useToggle';
import { LogOut } from '@lyearn/icons';
import { BasePopoverContent, Button, PopoverOrigin, Skeleton, Typography } from '@lyearn/ui';

import UserMenuComponent from '../../../UserMenu';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const popoverClasses = {
  paper: 'rounded-8 shadow-03 border-solid border border-field w-160 py-3 mt-6 overflow-visible',
};

const UserMenu = () => {
  const user = useUser();
  const { name, emailId, profilePicture } = user;
  const [anchorElem, setAnchorElem] = useState<Element | null>(null);
  const [open, { toggle }] = useToggle(false);

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElem(event.currentTarget);
    toggle();
  };
  return user.fetching ? (
    <div className="flex items-center me-26 space-s-12">
      <Skeleton animation="wave" height={30} variant="circular" width={30} />
      <Skeleton animation="wave" variant="rectangular" width={100} />
    </div>
  ) : (
    <>
      <UserMenuComponent
        emailId={emailId}
        name={name}
        open={open}
        profileImg={profilePicture}
        onClick={clickHandler}
      />
      <BasePopoverContent
        anchorEl={anchorElem}
        anchorOrigin={anchorOrigin}
        classes={popoverClasses}
        open={open}
        transformOrigin={transformOrigin}
        onClose={toggle}>
        <div className="flex flex-col items-start px-0 text-text-secondary">
          <Button
            className="w-full justify-start"
            variant="ghost"
            onClick={() => logout(user.refreshUser, '/')}>
            <Typography
              className="flex w-full items-center text-text-secondary hover:text-text-primary"
              variant="body-short-02">
              <LogOut className="h-20 w-20 me-6" />
              Logout
            </Typography>
          </Button>
        </div>
      </BasePopoverContent>
    </>
  );
};

export default UserMenu;
