import { useEffect } from 'react';

import useToggle from '@lyearn/core/hooks/useToggle';

import { HeaderProps } from '../types';

const useHeader = ({
  headerType,
  solidOverlayAfter,
}: Pick<HeaderProps, 'headerType' | 'solidOverlayAfter'>) => {
  const [solidHeader, { toggle: toggleSolidHeader }] = useToggle(true);

  const setHeaderStyle = () => {
    if (headerType === 'overlay') {
      const elem = document.querySelector(solidOverlayAfter || '');
      const elemBottom = elem?.getBoundingClientRect?.().bottom;

      if (elemBottom !== undefined) {
        if (elemBottom < 0 && !solidHeader) {
          toggleSolidHeader();
        } else if (elemBottom > 0 && solidHeader) {
          toggleSolidHeader();
        }
      }
    }
  };

  useEffect(() => {
    if (headerType === 'overlay') {
      setHeaderStyle();
      window.addEventListener('scroll', setHeaderStyle);
      return () => {
        window.removeEventListener('scroll', setHeaderStyle);
      };
    }
  });

  return { solidHeader };
};

export default useHeader;
