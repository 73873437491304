import React from 'react';
import cn from 'classnames';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import Link from 'next/link';

import { logoContainer } from '../../styles';

const BrandInfo = ({ isHomepage }: { isHomepage: boolean }) => {
  const { site }: TSFixMe = useInstanceConfig();
  const { logoUrl, logoAlt, siteTitle, logoDimensions } = site.homepageData.header;
  const TitleTag = isHomepage ? 'h1' : 'p';

  return (
    <>
      <Link className={cn(logoContainer(logoDimensions), '')} href="/">
        {/* https://github.com/vercel/next.js/issues/5533 */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <img alt={logoAlt || ''} className="h-full" src={logoUrl} />
      </Link>
      {siteTitle && <TitleTag className="hidden">{siteTitle}</TitleTag>}
    </>
  );
};

export default BrandInfo;
