import React from 'react';

import { Box, Typography } from '@lyearn/ui';

const Header = (props: { switchToLogin: Function }) => {
  const { switchToLogin } = props;
  return (
    <>
      <Typography as="h1" className="mb-6 text-center" variant="display-xxl">
        Create Account
      </Typography>
      <Typography
        as="div"
        className="mb-10 flex items-center justify-center"
        variant="body-short-02">
        Already have an account?
        <Box className="ms-4" onClick={(event) => switchToLogin(event)}>
          <Typography className="cursor-pointer underline" variant="body-short-02">
            Log in
          </Typography>
        </Box>
      </Typography>
    </>
  );
};

export default Header;
