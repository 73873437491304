import { useCallback, useEffect, useState } from 'react';
import useCheckoutSessionAndRedirect from 'data-op/actions/useCheckoutSessionAndRedirect';
import useOpenLearningSpace from 'hooks/useOpenLearningSpace';
import { useUser } from 'modules/context/Contexts/User';
import { useRouter } from 'next/router';
import {
  GeneralSettingsType,
  ProductContentTypeEnum,
  TransactionPaymentProviderEnum,
} from 'types/schema';

import { GenericProduct, PurchaseInfo } from '../types';

type Props = {
  product: GenericProduct;
  purchasableProduct?: PurchaseInfo['purchasableProduct'] | null;
  redirectUrl?: string;
};

/* TODO: refactor, add to affiliactions */
const useProductActions = ({ product, purchasableProduct, redirectUrl }: Props) => {
  const { openLearningSpace } = useOpenLearningSpace();
  const router = useRouter();
  const user = useUser();
  const [showLoading, setShowLoading] = useState(false);

  const { contentId, contentType, userPerformance } = product;
  const { id: productId, generalSettings, SEOSettings } = purchasableProduct || product;
  const { slug: product_slug } = SEOSettings || {};
  const { certificateOnCompletion = false } = (generalSettings as GeneralSettingsType) || {};
  const { awared: showCertificate, id: certificateId, completionDate } = product.certificate || {};
  const { progress } = userPerformance || {};
  const allotCertificate = progress === 100 && certificateOnCompletion && !showCertificate;

  const { checkout, fetching } = useCheckoutSessionAndRedirect({
    productId,
    quantity: 1,
    redirectUrl: redirectUrl || undefined,
    product: purchasableProduct ?? product,
  });

  useEffect(() => {
    if (fetching) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [fetching, setShowLoading]);

  const handleStartLearning = useCallback(async () => {
    const { purchased } = purchasableProduct || product;
    const corporateUserId = user.corporateUserId;

    if (!contentType || contentType === ProductContentTypeEnum.CorporateCourse) {
      setShowLoading(true);
      if (!purchased) {
        const response = await checkout();
        if (response && response.paymentProvider === TransactionPaymentProviderEnum.Na) {
          if (!corporateUserId) {
            user?.refreshUser(true);
          }
        } else {
          setShowLoading(false);
          return;
        }
      } else if (!corporateUserId) {
        await user.refreshUser();

        if (!user.corporateUserId) {
          setShowLoading(false);
          return;
        }
      }

      setShowLoading(false);
      if (contentId) {
        openLearningSpace(
          contentId,
          productId,
          certificateOnCompletion,
          allotCertificate,
          !!product?.isChildProduct,
          certificateId,
          completionDate,
        );
      }

      return true;
    } else if (contentType === ProductContentTypeEnum.Course) {
      if (!purchased) {
        const response = await checkout();
        if (response && response.paymentProvider !== TransactionPaymentProviderEnum.Na) {
          setShowLoading(false);
          return;
        }
      }
      router.push(`/${product_slug ?? router.query.product_slug}/view/${contentId}`);
    }
  }, [
    allotCertificate,
    certificateId,
    certificateOnCompletion,
    completionDate,
    contentId,
    contentType,
    openLearningSpace,
    product,
    purchasableProduct,
    productId,
    product_slug,
    router,
    user,
    checkout,
  ]);

  const handleBuyClick = async () => {
    try {
      setShowLoading(true);
      await checkout();
    } finally {
      setShowLoading(false);
    }
  };

  return { showLoading, handleStartLearning, handleBuyClick };
};

export default useProductActions;
