import { useUser } from 'modules/context/Contexts/User';

import { ButtonActions, CTAProps } from '../types';
import useButtons from './useButtons';
import useCtaActions from './useCtaActions';

type Props = Pick<CTAProps, 'product' | 'fetching' | 'showMoreInfo' | 'moreInfoText'> & {
  user: ReturnType<typeof useUser>;
  showLoading?: boolean;
  disabled?: boolean;
  onAction?: (action: ButtonActions) => void;
};

const useCtaButtons = ({ product, user, moreInfoText, fetching, showMoreInfo }: Props) => {
  const { onAction, showCertificate, toggleCertificate, loading } = useCtaActions({ product });
  const { PrimaryButton, SecondaryButton } = useButtons({
    product,
    user,
    fetching: fetching || loading,
    moreInfoText,
    showMoreInfo,
    onAction,
  });

  return { PrimaryButton, SecondaryButton, showCertificate, toggleCertificate };
};

export default useCtaButtons;
