import React from 'react';
import { useUser } from 'modules/context/Contexts/User';

import { Skeleton } from '@lyearn/ui';

import AuthButtons from '../AuthButtons';
import UserMenu from '../UserMenu';

const UserAuth = (props: TSFixMe) => {
  const user = useUser();
  if (user.fetching) {
    return <Skeleton animation="wave" height={40} variant="rectangular" width={140} />;
  }
  if (user.id) {
    return <UserMenu />;
  }
  return <AuthButtons {...props} />;
};

export default UserAuth;
