import { css } from '@emotion/css';

export const collectionSubHeader = css`
  min-height: 12.1rem;
`;

export const collectionCustomButton = css`
  background: var(--bg-primary) !important;
  color: var(--interactive-primary) !important;
  &:hover {
    background: var(--bg-secondary) !important;
  }
`;

export const centeredStyle = css`
  max-width: 112rem;
  margin: 0 auto;
`;

export const widthContent = css`
  width: fit-content;
`;
