import { GenericProduct } from 'modules/product/types';

export type CTAProps = {
  product: GenericProduct;
  fetching: boolean;
  showMoreInfo?: boolean;
  moreInfoText?: React.ReactNode;
  hidePrice?: boolean;
};

export enum ButtonActions {
  LogIn = 'LOGIN',
  BuyNow = 'BUY_NOW',
  StartLearning = 'START_LEARNING',
  ViewCertificate = 'VIEW_CERTIFICATE',
  OpenProduct = 'OPEN_PRODUCT',
}
