import cn from 'classnames';
import { HeaderProps } from 'components/Header/types';

import { Box } from '@lyearn/ui';

import useHeaderBodyComponents from './hooks/useHeaderBodyComponents';
import { absoluteCenter } from './styles';

const HeaderBody: React.FC<HeaderProps> = (props) => {
  const { LeftNodeComponent, MiddleNodeComponent, RightNodeComponent } =
    useHeaderBodyComponents(props);

  const { middleNodeAlign } = props;
  return (
    <>
      <Box className="flex flex-grow items-center">{LeftNodeComponent}</Box>

      {MiddleNodeComponent ? (
        <Box
          className={cn('relative flex h-full flex-grow items-center justify-center', {
            [absoluteCenter]: middleNodeAlign === 'center',
          })}>
          {MiddleNodeComponent}
        </Box>
      ) : null}

      <Box className="flex flex-grow justify-end">{RightNodeComponent}</Box>
    </>
  );
};

export default HeaderBody;
