import { css } from '@emotion/css';

export const authWrapper = css`
  width: fit-content;
  height: fit-content;
`;

export const dialogStyle = css`
  width: 55.8rem;
  max-width: 55.8rem !important;
  /* height: 50rem; */
`;
