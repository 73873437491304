import React from 'react';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

import { Typography } from '@lyearn/ui';

const ContactUs = () => {
  const { site } = useInstanceConfig();

  if (!site.contactUsUrl) {
    return null;
  }

  return (
    <a
      className="text-interactive-primary me-20 ms-auto"
      href={site.contactUsUrl}
      rel="noreferrer"
      target="_blank">
      <Typography variant="heading-xxs">Contact Us</Typography>
    </a>
  );
};

export default ContactUs;
