import useRest from 'helpers/hooks/useRest';
import backend_url from 'helpers/url';

const useResendEmail = () => {
  return useRest(`${backend_url}/auth/resendVerificationEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default useResendEmail;
