import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { PurchaseInfo } from 'modules/product/types';
import { CurrencySymbol } from 'types/enums/CurrencySymbol';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Box, mergeOverrides, Typography } from '@lyearn/ui';

interface Overrides {
  priceProps?: React.ComponentProps<typeof Typography>;
  strikedPriceProps?: React.ComponentProps<typeof Typography>;
}

const DefaultOverrides = (direction: PriceProps['finalPriceSide']): Overrides => ({
  priceProps: {
    variant: 'heading-xs',
    color: 'text-text-primary',
    className: '',
  },
  strikedPriceProps: {
    variant: 'body-short-02',
    color: 'text-text-placeholder',
    className: cn(
      'line-through text-text-placeholder',
      { 'ms-6': direction === 'left' },
      { 'me-6': direction === 'right' },
    ),
  },
});

export interface PriceProps extends DefaultProps {
  purchaseInfo: PurchaseInfo;
  finalPriceSide?: string;
  size?: 'heading-xs' | 'heading-m' | 'heading-xl';
  showFree?: boolean;
  isCollectionChild?: boolean;
  overrides?: Overrides;
}

const Price: FunctionComponent<PriceProps> = (props) => {
  const { purchaseInfo, finalPriceSide = 'left', overrides } = props;

  const { finalPrice, currency, price, isDiscounted } = purchaseInfo;

  const symbol = CurrencySymbol[currency?.toLowerCase() as keyof typeof CurrencySymbol];

  const mergedOverrides = mergeOverrides(DefaultOverrides(finalPriceSide), overrides);

  const strikedPrice = (
    <Typography {...mergedOverrides?.strikedPriceProps}>{`${symbol}${price}`}</Typography>
  );

  return (
    <Box className="flex items-center">
      {finalPriceSide === 'right' && isDiscounted ? strikedPrice : null}
      <Typography {...mergedOverrides?.priceProps}>
        {finalPrice > 0 ? `${symbol}${finalPrice}` : price > 0 ? 'Free' : ''}
      </Typography>
      {finalPriceSide === 'left' && isDiscounted ? strikedPrice : null}
    </Box>
  );
};

export default Price;
