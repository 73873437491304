import { css } from '@emotion/css';

export const bannerWrapper = css`
  max-width: 112rem;
  margin: 0 auto;
  grid-template-areas: 'content button';
  grid-row-gap: 1.2rem;
  @media (max-width: 767px) {
    grid-template-areas: 'title button' 'content content';
  }
`;

export const buttonWrapper = css`
  min-width: 15rem;
`;

export const contentWrapper = css`
  grid-area: content;
`;

export const titleWrapper = css`
  grid-area: title;
`;
