import { css } from '@emotion/css';

export const signupRoot = css`
  .signup-container {
    max-width: 43.8rem;
    width: calc(100% - 8rem);
  }
  form {
    width: 100%;
    /* width: calc(100% + 2rem); */
    /* margin-inline-start: -1rem; */
    /* margin-inline-end: -1rem; */
  }
  .MuiFormControlLabel-root {
    display: flex;
    .MuiIconButton-label {
      width: 3rem;
      height: 3rem;
      align-items: center;
      display: flex;
    }
    input[type='checkbox'] {
      opacity: 0;
      display: none;
    }
  }
`;

export const orStyle = css`
  ::before,
  ::after {
    content: '';
    display: block;
    width: 5rem;
    height: 0.1rem;
    background: #a0a0a0;
    position: absolute;
    top: 50%;
  }
  ::before {
    left: -6.2rem;
  }
  ::after {
    right: -6.2rem;
  }
`;

export const facebook = css`
  background: #227bef;
`;
