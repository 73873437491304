import { ProductContentTypeEnum } from 'types/schema';

const curriculumIconMap = {
  assignmentCard: 'notepad',
  learningCard: 'textContent',
};

const getCurriculumSections = (contentType: ProductContentTypeEnum, curriculumData: any) => {
  let sections = [];

  if (contentType === ProductContentTypeEnum.CorporateCourse) {
    sections = curriculumData.length
      ? curriculumData[0].children.map((section: TSFixMe) => {
          return {
            title: section.name,
            description: section.description,
            items: section.children.map((item: TSFixMe) => {
              return {
                title: item.name,
                icon: curriculumIconMap[item.contentType as keyof typeof curriculumIconMap],
              };
            }),
          };
        })
      : [];
  } else {
    sections = (curriculumData || []).map((section: TSFixMe) => {
      return {
        title: section.label,
        description: '',
        items: section.items.map((item: TSFixMe) => {
          return {
            title: item.label,
            icon: curriculumIconMap.learningCard,
          };
        }),
      };
    });
  }

  return sections;
};

export default getCurriculumSections;
