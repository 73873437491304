import React, { forwardRef } from 'react';
import cn from 'classnames';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Box, Typography } from '@lyearn/ui';

import { User } from '../../types/interfaces';

interface UserType extends User, DefaultProps {
  ref: any;
  namePosition?: 'left' | 'right';
}

const UserMenu = forwardRef((props: UserType, ref: any) => {
  const { name, emailId, profileImg, className, onClick, open, namePosition = 'right' } = props;
  const initial = name ? name[0] : emailId ? emailId[0] : '';
  return (
    <Box
      className={cn(
        'flex h-40 cursor-pointer items-center rounded-24 px-20 me-10 hover:bg-interactive-secondary xs:me-6',
        className,
        { 'bg-interactive-secondary': open },
        { 'flex-row-reverse': namePosition === 'left' },
      )}
      onClick={onClick as any}
      {...({ ref } as any)}>
      <Box className="h-24 w-24 pb-0">
        {profileImg ? (
          <img alt="profile" className="rounded-16" src={profileImg} />
        ) : (
          <Box className="flex h-full w-full items-center justify-center rounded-16 bg-text-placeholder">
            <Box className="label-01 text-white">{initial.toUpperCase()}</Box>
          </Box>
        )}
      </Box>
      <Typography
        as="div"
        className={cn('text-refreshUser', namePosition === 'left' ? 'me-10' : 'ms-10')}
        variant="body-short-02">
        {name || emailId}
      </Typography>
    </Box>
  );
});

export default UserMenu;
