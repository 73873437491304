import React from 'react';
import ContactUs from 'components/ContactUsLink';
import AuthWrapper from 'modules/auth/components/AuthWrapper';

import { Button } from '@lyearn/ui';

import { AuthButtonsType } from './types';

type buttonVariant = React.ComponentProps<typeof Button>['variant'];
interface ButtonProps {
  text: string;
  className?: string;
  variant?: buttonVariant;
}

const AuthButtons = ({
  authType,
  primaryBtn,
  secondaryBtn,
}: {
  authType: AuthButtonsType;
  primaryBtn?: ButtonProps;
  secondaryBtn?: ButtonProps;
}) => {
  if (authType === AuthButtonsType.LoginSignup) {
    return (
      <>
        <ContactUs />
        <AuthWrapper>
          <Button
            className={primaryBtn?.className ? '' : 'text-text-primary me-20 xs:me-10'}
            variant={primaryBtn?.variant || 'ghost'}>
            {primaryBtn?.text || 'Log In'}
          </Button>
        </AuthWrapper>
        {primaryBtn?.text && (
          <AuthWrapper authType="signup">
            <Button
              className={secondaryBtn?.className}
              variant={secondaryBtn?.variant || 'outlined'}>
              {secondaryBtn?.text || 'Create Account'}
            </Button>
          </AuthWrapper>
        )}
      </>
    );
  }
  return (
    <>
      <ContactUs />
      <AuthWrapper>
        <Button className="me-20 xs:me-10" variant="filled">
          Get Started
        </Button>
      </AuthWrapper>
    </>
  );
};

export default AuthButtons;
