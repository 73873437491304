import React from 'react';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

import { Box, Typography } from '@lyearn/ui';

const FormHeader = (props: { switchToSignup: Function }) => {
  const { switchToSignup } = props;
  const { site } = useInstanceConfig();
  return (
    <>
      <Typography as="h1" className="mb-6 text-center" variant="display-xxl">
        Log In
      </Typography>
      <Typography
        as="div"
        className="mb-10 flex items-center justify-center"
        variant="body-short-02">
        New to {site.displayCompanyName}?
        <Box className="ms-4" onClick={(event) => switchToSignup(event)}>
          <Typography className="cursor-pointer underline" variant="body-short-02">
            Create Account
          </Typography>
        </Box>
      </Typography>
    </>
  );
};

export default FormHeader;
