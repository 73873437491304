import React from 'react';
import { createContext } from 'helpers/createContext';
import useProductCertificate from 'modules/product/hooks/useProductCertificate';
import useProductCurriculum from 'modules/product/hooks/useProductCurriculum';
import useTransformCollectionChild from 'modules/product/hooks/useTransformCollectionChild';
import useUserProducts from 'modules/product/hooks/useUserProducts';
import { GenericProduct, UserProduct } from 'modules/product/types';

const PRODUCT = 'Product';

type ProductContextValue = {
  product: GenericProduct;
  fetching: boolean;
  isStale?: boolean;
};

export type ProductContextProps = {
  products: UserProduct[];
  productId?: string;
  contentCurriculum: any;
};

const [ProductContextProvider, useProductContext] = createContext<ProductContextValue>(PRODUCT);

const _ProductContextProvider: React.FC<React.PropsWithChildren<ProductContextProps>> = ({
  products,
  contentCurriculum,
  children,
  productId,
}) => {
  const { products: _products, fetching, isStale } = useUserProducts(products);

  const { product: childProduct } = useTransformCollectionChild(_products, productId);

  const { product: productWithCurriculum } = useProductCurriculum(
    productId && childProduct ? childProduct : _products[0],
    contentCurriculum,
  );

  const { product: productWithCertificate, fetching: fetchingCertificate } =
    useProductCertificate(productWithCurriculum);

  return (
    <ProductContextProvider
      fetching={fetching || fetchingCertificate}
      isStale={isStale}
      product={productWithCertificate}>
      {children}
    </ProductContextProvider>
  );
};

export { _ProductContextProvider as ProductContextProvider, useProductContext };
export default _ProductContextProvider;
