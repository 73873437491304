import { ProductTypeEnum } from 'types/schema';
import getCurriculumSections from 'utils/getCurriculumSections';

import { GenericProduct } from '../types';

const useProductCurriculum = (product: GenericProduct, contentCurriculum: TSFixMe) => {
  if (product.type === ProductTypeEnum.Collection || !product.contentId || !product.contentType) {
    return { product };
  }

  const sections = getCurriculumSections(product.contentType!, contentCurriculum);

  product.content.curriculum = sections;

  return { product };
};

export default useProductCurriculum;
