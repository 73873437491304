// libs
import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

const useScrollSpy = (sections: string[], initialValue: string) => {
  const [activeSection, setActiveSection] = useState<string>(initialValue);

  const updateSections = throttle(
    useCallback(() => {
      const scrollTop = window.scrollY;
      const scrollHeight = window.innerHeight;
      // const pageBottom = scrollTop + scrollHeight;

      const offsets: { top: number; bottom: number; section: string }[] = [];

      //calulating offsets for each section
      sections.forEach((section) => {
        const target = document.getElementById(section);

        if (!target) {
          return;
        }

        const rect = target.getBoundingClientRect();
        if (rect.width || rect.height) {
          offsets.push({ top: rect.top, bottom: rect.bottom, section });
        }
      });

      offsets.sort((a, b) => a.top - b.top);

      let bestMatch: { section?: string } = {};

      //using offsets and current scrollY position calucate deltas for each section
      //and set one with least delta as bestMatch
      for (let i = 0; i < offsets.length; i++) {
        // const isInView = offsets[i]?.top <= scrollTop + scrollHeight / 2;
        // console.log(offsets[i]?.top <= scrollTop + scrollHeight / 2);
        // const delta = Math.abs(offsets[i]?.top - scrollTop + scrollHeight / 2);
        const screenMiddle = scrollHeight / 2;

        if (!bestMatch || !bestMatch?.section) {
          // bestMatch = { section: offsets[i].section };
        }
        if (offsets[i].top < screenMiddle) {
          bestMatch = { section: offsets[i].section };
        }
      }

      //set active section to best match
      if (bestMatch?.section) {
        setActiveSection(bestMatch.section);
        return;
      } else {
        setActiveSection(initialValue);
      }

      // At bottom, select the last section
      if (scrollTop >= scrollHeight) {
        setActiveSection(offsets[offsets.length - 1]?.section);
        return;
      }
    }, [sections, initialValue]),
    10,
  );

  // Run updateSections onMount
  useEffect(() => {
    updateSections();
  }, [updateSections]);

  // Attach updateSections to scroll event
  useEffect(() => {
    window.addEventListener('scroll', updateSections);

    return () => {
      window.removeEventListener('scroll', updateSections);
    };
  }, [updateSections]);

  return activeSection;
};

export default useScrollSpy;
