import { useUser } from 'modules/context/Contexts/User';
import { PerformanceStatusEnum } from 'types/schema';

import { useProductUserCertificateQuery } from '../graphql/queries/generated/productUser';
import { CertificateVersions, GenericProduct } from '../types';

/**
 * Get certificate info for the product,
 * expects `userPerformance` field to exist to calculate certificate related fields
 */
const useProductCertificate = (product: GenericProduct) => {
  const user = useUser();

  /*  const [{ data, fetching, error }] = useProductUserCertificateQuery({
    variables: {
      filters: {
        productId: product.id,
        userId: user.id,
      },
    },
    requestPolicy: 'network-only',
    pause: !user.id || !product.userPerformance,
  });

  if (
    (product.userPerformance?.progress === 100 ||
      product.userPerformance?.completionStatus === PerformanceStatusEnum.Completed) &&
    !fetching
  ) {
    const certificateId = data?.productUser?.certificateId;

    if (certificateId) {
      product.certificate = {
        awared: true,
        completionDate: data?.productUser?.createdAt!,
        id: certificateId,
        certificateVersion: CertificateVersions.V1,
      };
    }
  }

  if (!product.certificate) { */
  const certificateV2 = product.userPerformance?.certificate;

  if (certificateV2 && !product.isChildProduct) {
    product.certificate = {
      awared: true,
      completionDate: product.userPerformance?.completedOn!,
      certificateVersion: CertificateVersions.V2,
      ...certificateV2,
      id: certificateV2.sharedId,
    };
  }
  /* } */

  return { product, fetching: false };
};

export default useProductCertificate;
