import { useCallback, useEffect } from 'react';
import { MenuProps } from 'components/Header/types';
import queryString from 'query-string';
import scrollIntoView from 'scroll-into-view-if-needed';

const useNavScrollAndClick = (behaviour: MenuProps['behaviour']) => {
  const onNavClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const sectionKey = event.currentTarget.getAttribute('data-section-key');
      const element = document.getElementById(sectionKey as string);
      if (!element || behaviour === 'static') {
        const query = { sec: sectionKey };
        window.location.href = queryString.stringifyUrl({ url: '/', query });
        return;
      }

      scrollIntoView(element, { behavior: 'smooth', block: 'start' });
    },

    [behaviour],
  );

  useEffect(() => {
    if (behaviour === 'scroll') {
      const { url, query } = queryString.parseUrl(window.location.href);
      if (query.sec) {
        const hash = query.sec;
        delete query.sec;
        window.history.replaceState(null, '', queryString.stringifyUrl({ url, query }));
        setTimeout(() => {
          scrollIntoView(document.getElementById(hash as string) as Element, {
            behavior: 'smooth',
            block: 'start',
          });
        }, 100);
      }
    }
  }, [behaviour]);

  return { onNavClick };
};

export default useNavScrollAndClick;
