import { useUser } from 'modules/context/Contexts/User';
import { GenericProduct } from 'modules/product/types';
import { UserStatusEnum } from 'types/schema';

import { ButtonVariantProps } from '@lyearn/ui';

import { ButtonActions, CTAProps } from '../types';

type Props = Pick<CTAProps, 'product' | 'fetching'> & {
  purchasableProduct: GenericProduct;
  user: ReturnType<typeof useUser>;
  showLoading?: boolean;
  disabled?: boolean;
  onAction?: (action: ButtonActions) => void;
};

const getPrimaryButton = ({
  product,
  purchasableProduct,
  fetching,
  user,
  showLoading,
  onAction,
}: Props) => {
  let buttonText = 'Buy Now';
  let buttonVariant: ButtonVariantProps['variant'] = 'filled';
  let buttonAction = ButtonActions.LogIn;

  const userEmailNotVerified = user.status === UserStatusEnum.EmailNotVerified;
  const productIsFree = purchasableProduct.derivedFields.purchaseInfo.isFree;
  const userPerformance = product.userPerformance;
  const { certificateOnCompletion = false } = product.generalSettings || {};
  const productComplete = userPerformance?.progress === 100; /*  &&
    userPerformance.completionStatus === PerformanceStatusEnum.Completed */
  const certificateAwarded = product.certificate?.awared;

  if (product.purchased || productIsFree) {
    buttonText = 'Start Learning';
    buttonAction = ButtonActions.StartLearning;
  }

  if (product.userPerformance?.progress) {
    buttonText = 'Resume Learning';
  }
  if (productComplete) {
    buttonText = 'Review Learning';
  }

  if (!product.purchased && !productIsFree) {
    buttonAction = ButtonActions.BuyNow;
  }

  if (productComplete && certificateOnCompletion && certificateAwarded) {
    buttonText = 'View Certificate';
    buttonAction = ButtonActions.ViewCertificate;
  }

  return {
    text: buttonText,
    action: buttonAction,
    disabled:
      fetching ||
      (userEmailNotVerified && (product.purchased || product.derivedFields?.purchaseInfo?.isFree)),
    loading: fetching,
    variant: buttonVariant,
    onClick: () => onAction?.(buttonAction),
    show: true,
  };
};

export default getPrimaryButton;
