import { css } from '@emotion/css';

import { HeaderProps } from './types';

export const header = (height: string = '8rem') => css`
  height: ${height};
`;

export const centeredHeader = css`
  max-width: 118rem;
  @media (max-width: 1670px) {
    max-width: 112rem;
  }
  margin: 0 auto;
`;

export const headerClass = ({ centered, height }: { centered: boolean; height?: string }) => {
  if (centered) {
    return `${header(height)} ${centeredHeader}`;
  } else {
    return `w-full ${header(height)}`;
  }
};

export const navBtnContainer = css`
  transform: translateX(-50%);
  left: 50%;
`;

export const navButton = css`
  .overlay & {
    color: #fff;
  }

  .overlaySolid &,
  .stickyHeader & {
    color: #ccc;
  }

  color: #ccc;
`;

export const selectedTab = css`
  background: #cccccc;
`;

export const navButtonText = css`
  font-weight: 400;

  .overlaySolid & {
    opacity: 0.6;
  }
`;

export const navHeaderStyles = (transparent?: boolean, behaviour?: 'static' | 'scroll') => css`
  height: 6rem;
  ${transparent && behaviour !== 'static' && transparentContainerGradient}
`;

export const transparentContainerGradient = css`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const logoContainer = ({ height = '2.7rem' } = {}) => css`
  height: ${height};
  @media (max-width: 768px) {
    height: 2.3rem;
  }
`;

export const headerWrapper = css`
  /* background: rgba(255, 255, 255, 0.96); */
`;

export const mobileNavButton = css`
  top: 2rem;
  left: 2rem;
  z-index: 2000;
`;

export const overlayHeaderStyles = (
  solidHeader: boolean,
  headerStyles: HeaderProps['headerStyles'],
) => css`
  background: ${solidHeader
    ? headerStyles?.solidBackgroundColor
    : headerStyles?.transparentBackgroundColor};
`;
