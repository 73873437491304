import React from 'react';
import cn from 'classnames';
import { facebook } from 'components/Login/styles';
import useAuthentication from 'modules/auth/hooks/useAuthentication';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { useRouter } from 'next/router';
import { FetchingStatus } from 'types/enums/FetchType';
import LoginTypes from 'types/enums/LoginTypes';

import { EmailOutline, Facebook, Google, LinkedIn } from '@lyearn/icons';
import { Box, Button, Typography } from '@lyearn/ui';

import Header from './Header';
import { orStyle } from './styles';

const SignupActions = (props: {
  switchToLogin?: Function;
  handleClose?: Function;
  redirectPath?: string;
}) => {
  const router = useRouter();
  const { fetchStatus, handleSocialAuth } = useAuthentication();
  const { site } = useInstanceConfig();
  const { social } = site.loginTypes;

  const { switchToLogin = () => {}, redirectPath, handleClose = () => {} } = props;

  const handleClick = (e: any, socialType: LoginTypes) => {
    e.stopPropagation();
    handleSocialAuth({
      socialType,
      type: 'signup',
      redirectPath,
      handleClose,
    });
  };

  return (
    <>
      <Header switchToLogin={switchToLogin as Function} />
      <Box className={social.length > 0 ? 'mt-40' : ''} />
      {social.includes(LoginTypes.LinkdIn) ? (
        <Button
          className="mb-10 bg-brand-linkedin normal-case hover:bg-brand-linkedin-hover"
          disabled={fetchStatus === FetchingStatus.Fetching}
          fullWidth={true}
          size="large"
          variant="filled"
          onClick={(e) => handleClick(e, LoginTypes.LinkdIn)}>
          <LinkedIn className="h-20 w-20 fill-current me-6" />
          <Typography variant="heading-xs">Sign Up with LinkedIn</Typography>
        </Button>
      ) : null}
      {social.includes(LoginTypes.Facebook) ? (
        <Button
          className={cn('mb-10 normal-case')}
          disabled={fetchStatus === FetchingStatus.Fetching}
          fullWidth={true}
          size="large"
          variant="outlined"
          onClick={(e) => handleClick(e, LoginTypes.Facebook)}>
          <Facebook className={cn('h-20 w-20 fill-current me-6', facebook)} />
          <Typography variant="heading-xs">Sign Up with Facebook</Typography>
        </Button>
      ) : null}
      {social.includes(LoginTypes.Google) ? (
        <Button
          className="mb-10 normal-case"
          disabled={fetchStatus === FetchingStatus.Fetching}
          fullWidth={true}
          size="large"
          variant="outlined"
          onClick={(e) => handleClick(e, LoginTypes.Google)}>
          <Google className="h-20 w-20 fill-current text-text-primary me-6" />
          <Typography variant="heading-xs">Sign Up with Google</Typography>
        </Button>
      ) : null}
      <Box className="text-gray-500 my-20 flex items-center justify-center">
        <Typography
          className={cn(orStyle, 'relative text-text-placeholder')}
          variant="body-long-01">
          Or
        </Typography>
      </Box>
      <Button
        className="mb-10 normal-case"
        fullWidth={true}
        size="large"
        variant="ghost"
        onClick={() =>
          router.push(`/signup?redirectPath=${encodeURIComponent(redirectPath || '')}`)
        }>
        <EmailOutline className="h-20 w-20 fill-current me-8" />
        <Typography variant="heading-xs">Sign up with Email</Typography>
      </Button>
    </>
  );
};

export default SignupActions;
