import { useUser } from 'modules/context/Contexts/User';
import { GenericProduct } from 'modules/product/types';

import { ButtonVariantProps } from '@lyearn/ui';

import { ButtonActions, CTAProps } from '../types';

type Props = Pick<CTAProps, 'product' | 'showMoreInfo' | 'moreInfoText'> & {
  purchasableProduct: GenericProduct;
  user: ReturnType<typeof useUser>;
  showLoading?: boolean;
  disabled?: boolean;
  onAction?: (action: ButtonActions) => void;
};

const getSecondaryButton = ({
  product,
  purchasableProduct,
  showLoading,
  moreInfoText,
  showMoreInfo,
  onAction,
}: Props) => {
  let buttonExists = false;
  let buttonText = moreInfoText ?? 'More Info';
  let buttonVariant: ButtonVariantProps['variant'] = 'filled';
  let buttonAction = ButtonActions.OpenProduct;

  if (showMoreInfo) {
    buttonExists = true;
  }

  if (product.userPerformance?.progress === 100 && product.certificate?.awared) {
    buttonExists = true;
    buttonVariant = 'outlined';
    buttonText = 'Review Learning';
    buttonAction = ButtonActions.StartLearning;
  }

  return {
    text: buttonText,
    action: buttonAction,
    disabled: false,
    loading: showLoading,
    variant: buttonVariant,
    onClick: () => onAction?.(buttonAction),
    show: buttonExists,
  };
};

export default getSecondaryButton;
