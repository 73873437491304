import { useCallback } from 'react';
import useProductActions from 'modules/product/hooks/useProductActions';
import { GenericProduct } from 'modules/product/types';
import getProductUrls from 'modules/product/utils/getProductUrls';
import { useRouter } from 'next/router';

import useToggle from '@lyearn/core/hooks/useToggle';

import { ButtonActions } from '../types';

type Props = {
  product: GenericProduct;
};
const useCtaActions = ({ product }: Props) => {
  const router = useRouter();
  const [showCertificate, { toggle: toggleCertificate }] = useToggle(false);
  const {
    SEOSettings,
    derivedFields: { purchaseInfo },
  } = product;
  const { slug } = SEOSettings || {};

  const productUrl = getProductUrls({ product }).productUrl;

  const {
    showLoading: loadingData,
    handleStartLearning,
    handleBuyClick,
  } = useProductActions({
    purchasableProduct: purchaseInfo.purchasableProduct ?? null,
    product,
    redirectUrl: productUrl,
  });

  const onAction = useCallback(
    (action: ButtonActions) => {
      switch (action) {
        case ButtonActions.BuyNow: {
          handleBuyClick();
          return;
        }
        case ButtonActions.StartLearning: {
          handleStartLearning();
          return;
        }
        case ButtonActions.ViewCertificate: {
          toggleCertificate();
          return;
        }
        case ButtonActions.OpenProduct: {
          router.push(`/${slug}`);
          return;
        }
      }
    },
    [handleStartLearning, toggleCertificate, handleBuyClick, router, slug],
  );

  return { onAction, showCertificate, toggleCertificate, loading: loadingData };
};

export default useCtaActions;
