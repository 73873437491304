import React from 'react';
import cn from 'classnames';
import Price from 'components/Price';
import { noop } from 'lodash';
import AuthWrapper from 'modules/auth/components/AuthWrapper';
import { useProductContext } from 'modules/context/Contexts/Product';
import { useUser } from 'modules/context/Contexts/User';
import useCtaButtons from 'modules/product/components/CTA/hooks/useCtaButtons';
import getProductUrls from 'modules/product/utils/getProductUrls';
import { useRouter } from 'next/router';

import { ArrowForward, ArrowLeftOutlineLegacy } from '@lyearn/icons';
import { Box, Button, Skeleton, Stack, Typography } from '@lyearn/ui';
import { TypographyColors, TypographyVariants } from '@lyearn/ui';

import { centeredStyle, collectionCustomButton, collectionSubHeader, widthContent } from './styles';

const priceOverrides = {
  priceProps: {
    color: 'text-text-white' as TypographyColors,
    variant: 'heading-m' as TypographyVariants,
  },
  strikedPriceProps: {
    color: 'text-text-white' as TypographyColors,
  },
};
const OfferingSubHeader = ({ centered }: TSFixMe) => {
  const user = useUser();
  const router = useRouter();
  const { product: _product, fetching } = useProductContext();

  const product = _product!;
  const parentProduct = _product.parentProduct;

  const { derivedFields } = product;
  const { purchaseInfo } = derivedFields;

  const { PrimaryButton } = useCtaButtons({
    product,
    user,
    fetching,
  });

  const hidePricing = product.purchased;

  const PricingRender =
    !hidePricing && !fetching ? (
      <Box className=" mb-8 me-8 sm:mb-0">
        <Price overrides={priceOverrides} purchaseInfo={purchaseInfo} size="heading-xl" />
      </Box>
    ) : null;

  const SkeletonRender = (
    <Skeleton animation="wave" height={40} variant="rectangular" width={210} />
  );

  const PrimaryButtonRender = (
    <Button
      className={collectionCustomButton}
      disabled={PrimaryButton.disabled}
      endIcon={<ArrowForward className="h-26 w-26 fill-current ms-6 rtl:rotate-180" />}
      loading={PrimaryButton.loading}
      size="medium"
      variant={PrimaryButton.variant}
      onClick={user.id ? PrimaryButton.onClick : noop}>
      {PrimaryButton.text}
    </Button>
  );

  const PrimaryButtonAuthRender = user.id ? (
    PrimaryButtonRender
  ) : (
    <AuthWrapper
      redirectPath={process.browser ? getProductUrls({ product }).productCheckoutUrl : '/'}
      onClick={PrimaryButton.onClick}>
      {PrimaryButtonRender}
    </AuthWrapper>
  );

  const ButtonsRender = <>{PrimaryButtonAuthRender}</>;

  return (
    <div
      className={cn(
        'sticky z-20 -mx-26 min-w-full bg-focus-primary px-26 py-30 xs:-mx-20 xs:h-auto xs:py-20 xs:px-20',
        collectionSubHeader,
      )}>
      <div
        className={cn('flex w-full items-center xs:flex-wrap', {
          [centeredStyle]: centered,
        })}>
        <div className="flex h-full w-1/2 flex-col justify-center xs:h-auto xs:w-full">
          <div
            className={cn(
              'heading-xs mb-6 flex cursor-pointer items-center text-text-white hover:text-text-placeholder',
              widthContent,
            )}
            onClick={() => router.push(`/${parentProduct?.SEOSettings?.slug}`)}>
            <ArrowLeftOutlineLegacy className="me-3 h-26 w-26 fill-current rtl:rotate-180" />
            Back to Collection
          </div>
          <Typography as="div" className="heading-xl text-text-white">
            {parentProduct?.name}
          </Typography>
        </div>
        <div className="flex w-1/2 items-center justify-end xs:h-auto xs:w-full xs:justify-start">
          <Stack direction="col">
            {PricingRender}
            <Stack>{fetching ? SkeletonRender : ButtonsRender}</Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default OfferingSubHeader;
