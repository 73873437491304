import React from 'react';
import cn from 'classnames';
import EmailNotVerifiedBanner from 'components/EmailNotVerifiedBanner';
import OfferingSubHeader from 'components/OfferingSubHeader';

import { Box } from '@lyearn/ui';

import HeaderBody from './components/HeaderBody';
import useHeader from './hooks/useHeader';
import { headerClass, headerWrapper, overlayHeaderStyles } from './styles';
import { HeaderProps } from './types';

const headerClasses = ({
  headerType,
  solidHeader,
  headerStyles,
}: HeaderProps & { solidHeader: boolean }) => {
  return cn(
    { 'sticky stickyHeader': headerType === 'sticky' },
    { fixed: headerType === 'overlay' },
    headerWrapper,
    [overlayHeaderStyles(solidHeader, headerStyles)],
    { overlaySolid: solidHeader && headerType === 'overlay' },
    { overlay: !solidHeader && headerType === 'overlay' },
  );
};

/* TODO: !! handle Email not verified and Offering sub header */
const Header: React.FC<HeaderProps> = (props) => {
  const {
    headerStyles = {},
    headerType = 'sticky',
    solidOverlayAfter,
    showOfferingSubHeader = false,
    centered = false,
    height,
  } = props;

  const { solidHeader } = useHeader({ headerType, solidOverlayAfter });

  return (
    <Box
      className={cn(
        'top-0 z-50 w-full bg-bg-primary px-26 xs:px-20',
        headerClasses({ headerType, solidHeader, headerStyles }),
      )}>
      <div
        className={cn(
          'flex flex-row items-center justify-between xs:mb-0',
          headerClass({ centered, height }),
        )}>
        <HeaderBody {...props} />
      </div>
      <EmailNotVerifiedBanner />
      {showOfferingSubHeader ? <OfferingSubHeader centered={centered} /> : null}
    </Box>
  );
};

export { HeaderBody };
export default Header;
