import { css } from '@emotion/css';

export const loginRoot = css`
  height: 66.5rem;
  max-height: 90%;
  .login-container {
    max-width: 43.8rem;
    width: calc(100% - 8rem);
  }
  form {
    width: 100%;
    /* width: calc(100% + 2rem); */
    /* margin-inline-start: -1rem; */
    /* margin-inline-end: -1rem; */
  }
`;

export const backdropFilter = css`
  /* filter: blur(50rem); */
  opacity: 0.5;
`;

export const orStyle = css`
  ::before,
  ::after {
    content: '';
    display: block;
    width: 5rem;
    height: 0.1rem;
    background: #a0a0a0;
    position: absolute;
    top: 50%;
  }
  ::before {
    left: -6.2rem;
  }
  ::after {
    right: -6.2rem;
  }
`;

export const facebook = css`
  /* background: #227bef; */
  color: #227bef;
`;
