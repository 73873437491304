import { useUser } from 'modules/context/Contexts/User';

import { ButtonActions, CTAProps } from '../types';
import getPrimaryButton from '../utils/getPrimaryButton';
import getSecondaryButton from '../utils/getSecondaryButton';

type Props = Pick<CTAProps, 'product' | 'fetching' | 'showMoreInfo' | 'moreInfoText'> & {
  user: ReturnType<typeof useUser>;
  showLoading?: boolean;
  disabled?: boolean;
  onAction?: (action: ButtonActions) => void;
};

const useButtons = ({ product, user, fetching, moreInfoText, showMoreInfo, onAction }: Props) => {
  const purchasableProduct = product.derivedFields.purchaseInfo?.purchasableProduct ?? product;

  const PrimaryButton = getPrimaryButton({ product, purchasableProduct, user, fetching, onAction });

  const SecondaryButton = getSecondaryButton({
    product,
    purchasableProduct,
    user,
    moreInfoText,
    showMoreInfo,
    onAction,
  });

  return { PrimaryButton, SecondaryButton: SecondaryButton.show ? SecondaryButton : null };
};

export default useButtons;
