import { useRouter } from 'next/router';

/* TODO: refactor, add to affiliactions */
const useOpenLearningSpace = () => {
  const router = useRouter();

  const openLearningSpace = async (
    offeringId: string,
    productId: string,
    certificateOnCompletion: boolean = false,
    allotCertificate: boolean = false,
    isChildProduct: boolean,
    certificateId?: string,
    completedOn?: string,
  ) => {
    const certificateData =
      certificateId && completedOn ? window.btoa(`${certificateId}__${completedOn}`) : '';
    const showCertificateButton = !isChildProduct;

    const viewerUrl = `/content?cId=${offeringId}&pId=${productId}&c=${certificateOnCompletion}&ac=${
      allotCertificate || false
    }&scb=${showCertificateButton}${certificateData ? `&cd=${certificateData}` : ''}`;

    router.push(viewerUrl, viewerUrl, { shallow: true });
  };

  return { openLearningSpace };
};

export default useOpenLearningSpace;
