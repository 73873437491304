import React from 'react';
import cn from 'classnames';
import { navButton, navButtonText, selectedTab } from 'components/Header/styles';
import useScrollSpy from 'hooks/useScrollSpy';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

import { Box, LinkButton, Stack, Typography } from '@lyearn/ui';

interface Section {
  sectionKey: string;
  label: string;
  //to show the section button in navbar or not
  showInNav: boolean;
}

const MenuItem = ({ isMobile, ...rest }: TSFixMe) => {
  if (isMobile) {
    return <MobileMenuItem {...rest} />;
  }
  return <DesktopMenuItem {...rest} />;
};

const MobileMenuItem = ({ section, onClick }: TSFixMe) => (
  <LinkButton
    key={section.sectionKey}
    className="my-16 flex w-full justify-start py-2 px-4 ps-20"
    color="external"
    data-section-key={section.sectionKey}
    onClick={onClick}>
    <Typography className={navButton} variant="heading-xs">
      {section.label}
    </Typography>
  </LinkButton>
);
const DesktopMenuItem = ({ section, onClick, activeSection }: TSFixMe) => (
  <Stack
    key={section.sectionKey}
    alignItems="center"
    className={cn('flex flex-col me-40 ', navButton)}
    data-section-key={section.sectionKey}
    direction="col"
    role="button"
    onClick={onClick}>
    <Typography
      className={cn(
        'transition-opacity duration-300',
        section.sectionKey === activeSection ? 'opacity-100' : navButtonText,
      )}
      variant="heading-xs">
      {section.label}
    </Typography>
    <Box
      className={cn('absolute bottom-0 mt-16 h-2 w-16 transition-all ease-linear', {
        [selectedTab]: section.sectionKey === activeSection,
      })}
    />
  </Stack>
);

const Items = ({ onNavClick, isMobile }: TSFixMe) => {
  const { site }: TSFixMe = useInstanceConfig();

  const { sections: _sections } = site.homepageData.header;

  const sections = _sections as Section[];
  const sectionKeys = sections.map((section) => section.sectionKey);

  const activeSection = useScrollSpy(sectionKeys, '');

  return (
    <>
      {sections
        .filter((section) => !!section.showInNav)
        .map((section) => (
          <MenuItem
            key={section.sectionKey}
            activeSection={activeSection}
            isMobile={isMobile}
            section={section}
            onClick={onNavClick}
          />
        ))}
    </>
  );
};

export default Items;
