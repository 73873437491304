import React, { useEffect, useState } from 'react';
import Alert from '@mui/lab/Alert';
import cn from 'classnames';
import { useUser } from 'modules/context/Contexts/User';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { useRouter } from 'next/router';
import { UserStatusEnum } from 'types/schema';

import { EmailOutline } from '@lyearn/icons';
import { BaseSnackbar, Box, Button, Typography } from '@lyearn/ui';

import useResendEmail from './hooks/useResendEmail';
import { bannerWrapper, buttonWrapper, contentWrapper, titleWrapper } from './styles';

const EmailNotVerifiedBanner = () => {
  const [open, setOpen] = useState(false);
  const user = useUser();
  const { id: userId, status, emailId } = user;
  const router = useRouter();
  const { pathname } = router;
  const [_status, _error, data, resendEmail] = useResendEmail();
  const { site } = useInstanceConfig();

  function handleResend(host: string) {
    resendEmail({
      requestBody: JSON.stringify({
        emailId,
        userId,
        storeName: site.storeName,
        host,
        subDomain: site.subdomain,
        entityType: 'store',
      }),
    });
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    if ((data as any)?.success) {
      setOpen(true);
    }
  }, [data, setOpen]);

  /* TODO: remove hack */
  if (
    !userId ||
    pathname.includes('about-yourself') ||
    pathname.includes('/terms') ||
    pathname.includes('/verify-email') ||
    pathname.includes('/signup')
  ) {
    return null;
  }

  const text =
    pathname === '/'
      ? `Thank you for joining ${site.displayCompanyName}! Verify your account by clicking on the link sent to your email `
      : `To continue your learning, verify your account by clicking on the link sent to your email `;

  if (userId && status === UserStatusEnum.EmailNotVerified) {
    return (
      <Box className="-mx-26 flex h-64 min-w-full items-center bg-bg-secondary px-26 py-12 xs:-mx-20 xs:mt-0 xs:h-auto xs:px-20 xs:py-26 ">
        <Box className={cn(bannerWrapper, 'grid w-full items-center')}>
          <Box className={cn(titleWrapper, 'hidden xs:block')}>
            <Typography className="heading-s">Verify Email</Typography>
          </Box>
          <Box className={cn(contentWrapper, 'flex items-center')}>
            <EmailOutline className="h-20 w-20 fill-current me-10 xs:hidden" />
            <Typography className="body-long-01 xl:heading-xxs xs:text-text-placeholder">
              {text}
              <Typography className="body-long-01 xl:heading-xxs text-link-01">
                {emailId}
              </Typography>
            </Typography>
          </Box>
          <Box className={cn(buttonWrapper, 'flex flex-grow justify-end')}>
            <Button
              variant="filled"
              onClick={() => {
                handleResend(window.location.host);
              }}>
              Resend Email
            </Button>
          </Box>
        </Box>
        <BaseSnackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={4000}
          className="bg-none"
          open={open}
          onClose={() => handleClose}>
          <Alert severity="success" onClose={handleClose}>
            <Typography className="body-long-01 xl:heading-xxs">
              Verification email successfully resent!
            </Typography>
          </Alert>
        </BaseSnackbar>
      </Box>
    );
  }
  return null;
};

export default EmailNotVerifiedBanner;
