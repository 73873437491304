import { useWindowSize } from 'react-use';
import { HeaderProps } from 'components/Header/types';

import { Box } from '@lyearn/ui';

import { HEADER_COMPONENTS_REF } from '../../../HeaderComponentsRef';
const useHeaderBodyComponents = (props: HeaderProps) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const mobileLayout = props.mobileLayoutProps;

  /* Left node */
  const LeftNode = !isMobile ? props.LeftNode : mobileLayout?.LeftNode ?? props.LeftNode;
  const LeftNodeRef = !isMobile
    ? props.LeftNodeRef
    : mobileLayout?.LeftNodeRef ??
      ('@lyearn/components/Header/BrandInfo' as keyof typeof HEADER_COMPONENTS_REF);
  const leftNodeProps = !isMobile
    ? props.leftNodeProps
    : mobileLayout?.leftNodeProps ?? props.leftNodeProps;

  let Left = {
    Node: LeftNode,
    Component: LeftNodeRef ? HEADER_COMPONENTS_REF[LeftNodeRef] : Box,
    props: leftNodeProps,
  };

  /* Middle node */
  const MiddleNode = !isMobile ? props.MiddleNode : mobileLayout?.MiddleNode ?? props.MiddleNode;
  const MiddleNodeRef = !isMobile
    ? props.MiddleNodeRef
    : mobileLayout?.MiddleNodeRef ?? props.MiddleNodeRef;
  const middleNodeProps = !isMobile
    ? props.middleNodeProps
    : mobileLayout?.middleNodeProps ?? props.middleNodeProps;

  let Middle = {
    Node: MiddleNode,
    Component: MiddleNodeRef ? HEADER_COMPONENTS_REF[MiddleNodeRef || ''] : null,
    props: middleNodeProps,
  };

  /* Right node */
  const RightNode = !isMobile ? props.RightNode : mobileLayout?.RightNode ?? props.RightNode;
  const RightNodeRef = !isMobile
    ? props.RightNodeRef
    : mobileLayout?.RightNodeRef ?? props.RightNodeRef;
  const rightNodeProps = !isMobile
    ? props.rightNodeProps
    : mobileLayout?.rightNodeProps ?? props.rightNodeProps;

  let Right = {
    Node: RightNode,
    Component: RightNodeRef ? HEADER_COMPONENTS_REF[RightNodeRef] : Box,
    props: rightNodeProps,
  };

  return {
    LeftNodeComponent: Left.Node ? Left.Node : <Left.Component {...Left.props} />,
    MiddleNodeComponent: Middle.Node ? (
      Middle.Node
    ) : Middle.Component ? (
      <Middle.Component {...Middle.props} />
    ) : null,
    RightNodeComponent: Right.Node ? Right.Node : <Right.Component {...Right.props} />,
  };
};

export default useHeaderBodyComponents;
