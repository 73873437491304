import React, { useState } from 'react';
import cn from 'classnames';
import LoginActions from 'components/Login/components/LoginActions';
import SignupActions from 'components/Signup/SignupActions';
import { useUser } from 'modules/context/Contexts/User';
import { DefaultProps } from 'types/interfaces/DefaultProps';

import { Dialog } from '@lyearn/ui';

import { authWrapper, dialogStyle } from './styles';

interface AuthProps extends DefaultProps {
  authType?: 'signup' | 'login';
  redirectPath?: string;
}

const AuthWrapper = (props: AuthProps) => {
  const { children, authType = 'login', redirectPath = '/', onClick, className } = props;
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(authType);
  const handleClose = () => {
    setContent(authType);
    setOpen(false);
  };
  const user = useUser();
  const handleClick = (e: any) => {
    if (!user.id) {
      e.preventDefault();
      e.stopPropagation();
      setOpen(true);
    } else {
      onClick?.(e);
    }
  };

  return (
    <>
      <div className={cn(authWrapper, className)} onClick={handleClick}>
        {children}
      </div>
      {open ? (
        <Dialog
          classes={{ paper: cn(dialogStyle, 'pt-26 px-40 pb-40 rounded-16') }}
          open={open}
          onClose={handleClose}>
          {content === 'login' ? (
            <LoginActions
              handleClose={handleClose}
              redirectPath={redirectPath}
              switchToSignup={() => setContent('signup')}
            />
          ) : (
            <SignupActions
              handleClose={handleClose}
              redirectPath={redirectPath}
              switchToLogin={() => setContent('login')}
            />
          )}
        </Dialog>
      ) : null}
    </>
  );
};

export default AuthWrapper;
