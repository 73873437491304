import { GenericProduct, UserProduct } from '../types';

type Props = {
  product: GenericProduct;
};
const getProductUrls = ({
  product,
}: Props): { productPath: string; productUrl: string; productCheckoutUrl: string } => {
  let productPath = '';
  let productCheckoutUrl = '';

  if (!process.browser) {
    return {
      productPath: '/',
      productUrl: `/`,
      productCheckoutUrl: '/',
    };
  }

  if (!product.isChildProduct && product.SEOSettings?.slug) {
    productPath = `/${product.SEOSettings?.slug}`;

    productCheckoutUrl = `/u/checkout?pId=${product.id}&ru=${window.location.pathname}`;
  }

  const parentProduct = product.parentProduct;

  if (product.isChildProduct && parentProduct) {
    productPath = `/${parentProduct.SEOSettings?.slug}/${product.id}`;

    productCheckoutUrl = `/u/checkout?pId=${parentProduct.id}&ru=/${parentProduct.SEOSettings?.slug}`;
  }

  return { productPath, productUrl: `${window.location.origin}${productPath}`, productCheckoutUrl };
};

export default getProductUrls;
