import { UserProduct } from '../types';

const useTransformCollectionChild = (products: UserProduct[], productId?: string) => {
  if (productId) {
    const product = products[0].children?.find((p) => p.id === productId);
    return { product: product! };
  }

  return { products };
};

export default useTransformCollectionChild;
