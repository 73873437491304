import { Box } from '@lyearn/ui';

import BrandInfo from './components/BrandInfo';
import HeaderMenu from './components/Menu';
import UserAuth from './components/UserAuth';

export const HEADER_COMPONENTS_REF = {
  '@lyearn/components/Header/BrandInfo': BrandInfo,
  '@lyearn/components/Header/UserAuth': UserAuth,
  '@lyearn/components/Header/Menu': HeaderMenu,
  '': Box,
};
