import React, { useCallback } from 'react';
import { useWindowSize } from 'react-use';
import cn from 'classnames';
import { mobileNavButton } from 'components/Header/styles';
import { MenuProps } from 'components/Header/types';

import useToggle from '@lyearn/core/hooks/useToggle';
import { Close, MenuOutline } from '@lyearn/icons';
import { Box, Dialog, Stack } from '@lyearn/ui';

import BrandInfo from '../BrandInfo';
import MenuItems from './components/MenuItems';
import useNavScrollAndClick from './hooks/useNavScrollAndClick';

const HeaderMenu = ({ behaviour }: MenuProps) => {
  const [open, { toggle }] = useToggle(false);
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const { onNavClick } = useNavScrollAndClick(behaviour);

  const _onNavClick = (e: any) => {
    if (open) {
      toggle();
    }
    onNavClick(e);
  };
  const renderMobileNavbar = useCallback(() => {
    const Icon = open ? Close : MenuOutline;
    return (
      <Box
        className={cn('z-100 absolute bg-transparent', mobileNavButton)}
        role="button"
        onClick={toggle}>
        <Icon className="h-24 w-24 text-text-primary" />
      </Box>
    );
  }, [open, toggle]);

  return isMobile ? (
    <>
      {renderMobileNavbar()}
      <Dialog fullScreen classes={{ paper: 'bg-bg-primary' }} open={open} onClose={toggle}>
        <Stack direction="col">
          {renderMobileNavbar()}
          <Stack
            alignItems="center"
            className="border-b border-divider py-20"
            justifyContent="center">
            <BrandInfo isHomepage={true} />
          </Stack>
          <Stack alignItems="center" className="py-10" direction="col" justifyContent="center">
            <MenuItems isMobile={isMobile} onNavClick={_onNavClick} />
          </Stack>
        </Stack>
      </Dialog>
    </>
  ) : (
    <MenuItems isMobile={isMobile} onNavClick={onNavClick} />
  );
};

export default HeaderMenu;
